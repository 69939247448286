@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

:root {
  --primaryColor: #00003c;
  --secondaryColor: #ffffee;
  --whiteColor: #ffffff;
  --blackColor: #000000;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primaryColor);
}

* {
  font-family: 'PT Serif', serif;
  font-style: normal;
  box-sizing: border-box;
  scrollbar-width: none;
  scroll-behavior: smooth;
}
